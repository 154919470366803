import React, { lazy, Suspense, useEffect, useState, useCallback } from 'react';
import Skeleton from 'react-loading-skeleton';
import filterObserver from "../observer/FilterService";
import { objToQueryString } from '../plugins/url-utils';
import queryString from 'query-string';

const renderLoader = () => <Skeleton height={20} width={60} />;
const DoctorItem = lazy(() => import('./DoctorItem'));
const BtnLoadMore = lazy(() => import('./BtnLoadMore'));

const DoctorsList = ({ url, appointment_locale, not_found }) => {
  const [filter, setFilter] = useState({});
  const [doctorsLength, setDoctorsLength] = useState(6);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [doctors, setDoctors] = useState(null);
  const [nextPage, setNextPage] = useState(null);

  const fetchDoctors = useCallback((queryString, replaceExisting) => {
    const timeStamp = new Date().getTime();
    const appKey = `Hrmn_haASjh76asjdh${timeStamp}||HrmnAID||`;
    const signature = btoa(appKey);

    const headers = {
      "Content-Type": "application/json",
      "Signature": signature,
      "Timestamp": timeStamp
    };

    setLoading(true);
    fetch(`${url}${queryString ? '?' + queryString : ''}`, { headers })
      .then(res => (res.ok ? res.json() : Promise.reject(res)))
      .then(data => {
        setDoctors(prevDoctors => replaceExisting ? data.data : [...prevDoctors, ...data.data]);
        setNextPage(data.pagination.next);
      })
      .catch(res => setError(res))
      .finally(() => setLoading(false));
  }, [url]);

  useEffect(() => {
    filterObserver.attach(onFilterUpdate);
    const parsed = queryString.parse(location.search);
    fetchDoctors(queryString.stringify(parsed), true);
    return () => filterObserver.detach(onFilterUpdate);
  }, [fetchDoctors]);

  const onFilterUpdate = data => {
    const updatedFilter = { ...filter, ...data };
    setFilter(updatedFilter);
    const params = objToQueryString(updatedFilter);
    fetchDoctors(params, true);
  };

  const handleLoadMore = () => {
    let params = { page: nextPage };
    if (Object.keys(filter).length) {
      params = { ...params, ...filter };
    }
    if (location.search) {
      const parsed = queryString.parse(location.search);
      params = { ...params, ...parsed };
    }
    fetchDoctors(objToQueryString(params), false);
  };

  const generateContent = () => {
    if (error) {
      return (
        <div>
          <div className="d-lg-none">
            <div className="alert alert-success text-center">
              Mohon maaf atas ketidaknyamanannya, sistem pemesanan janji temu RS Hermina sedang dalam masa perbaikan🙏
              <br/>
              Silakan lakukan pemesanan janji temu dengan menghubungi call center di 1500-488 atau download aplikasi Halo Hermina.
            </div>
          </div>
          <div className="d-none d-lg-block">
            <div className="success-box">
              <div className="face2">
                <div className="eye"></div>
                <div className="eye right-posistion"></div>
                <div className="mouth sad"></div>
              </div>
              <div className="shadow-card move"></div>
              <div className="message">
                <h1 className="alert">Mohon maaf atas ketidaknyamanannya, sistem pemesanan janji temu RS Hermina sedang dalam masa perbaikan🙏</h1>
                <p className="text-white">Silakan lakukan pemesanan janji temu dengan menghubungi call center di 1500-488 atau download aplikasi Halo Hermina.</p>
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (doctors) {
      if (doctors.length) {
        return (
          <div>
            <div className="row">
              {doctors.map(doctor => (
                <Suspense key={doctor.id} fallback={renderLoader()}>
                  <DoctorItem key={doctor.id} doctor={doctor.attributes} appointment_locale={appointment_locale} />
                </Suspense>
              ))}
            </div>
            {loading && renderLoading(doctorsLength)}
          </div>
        );
      } else {
        return (<h3 className="text-center">{not_found}</h3>);
      }
    } else {
      return renderLoading(doctorsLength);
    }
  };

  const renderLoading = totalDoctors => (
    <div className="row">
      {Array.from({ length: totalDoctors }).map((_, index) => (
        <div key={index} className="col-lg-4 col-md-6 col-sm-6">
          <div className="single-doctor-box card-full-height">
            <div className="doctor-image">
              <Skeleton circle={true} height={120} width={120} />
            </div>
            <div className="doctor-content">
              <h3 className="text-center">
                <Skeleton height={15} />
              </h3>
              <span className="text-center"><Skeleton height={12} /></span>
              <span className="text-center"><Skeleton height={12} /></span>
            </div>
            <div className="submit-btn">
              <Skeleton height={20} />
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <div className="doctor-wrapper">
      {generateContent()}
      {nextPage &&
        <div id="div_next_link">
          <div className="load-more text-center">
            <Suspense fallback={renderLoader()}>
              <BtnLoadMore onClick={handleLoadMore} />
            </Suspense>
          </div>
        </div>
      }
    </div>
  );
};

export default DoctorsList;
