import React from 'react';
import Skeleton from 'react-loading-skeleton';
import AvatarLoader from "./AvatarLoader";
import Image, { Shimmer } from 'react-shimmer';

const DoctorItem = ({ doctor, appointment_locale }) => {
  return (
    <div className="col-lg-4 col-md-6 col-sm-6">
      <div className="single-doctor-box card-full-height">
        <div className="doctor-image">
          <Image
            src={doctor.avatar.thumb.url}
            fallback={<Skeleton circle={true} height={120} width={120} />}
            errorFallback={() => (
              <AvatarLoader />
            )}
            NativeImgProps={{ alt: doctor.avatar.thumb.url }}
          />
          <a href={`/doctors/${doctor.slug}`} className="details-btn">
            <i className="fas fa-plus" />
          </a>
        </div>
        <div className="doctor-content">
          <h3>
            <a href={`/doctors/${doctor.slug}`} className="details-btn">
              {doctor.full_name || <Skeleton width={200} />}
            </a>
          </h3>
          <span><i className="fas fa-stethoscope"></i> {doctor.speciality || <Skeleton width={200} />}</span>
          <span className="text-success text-bold"><i className="fas fa-map-pin"></i> {doctor.practic_locations || <Skeleton width={200} />}</span>
        </div>
        {doctor.available_for_appointment && (
          <div className="submit-btn">
            <a href={`/doctors/${doctor.slug}/appointments/new`} className="btn-book btn-primary">{appointment_locale}</a>
          </div>
        )}
      </div>
    </div>
  );
};

export default DoctorItem;
